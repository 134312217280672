import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import Style from "../styles/rooms.module.css";
import Location from "../assets/icons/map-icon.png";
import Solar from "../assets/icons/solar-energy.png";
import Parking from "../assets/icons/parking-icon.png";
import Restaurant from "../assets/icons/restaurant.png";
import Offers from "../assets/icons/price-tag.png";
import SmartTV from "../assets/icons/smart-tv.png";
import Serene from "../assets/icons/renewable-energy.png";

const Facilities = () => {
  return (
    <a href=" " id="Facilities" className="anchor">
      <div className="root">
        <div className="sectiondiv">
          <div className={Style.roomtext}>Facilities</div>
        </div>
        <Container>
          <Row>
            <Col md={6} lg={4}>
              <div className={Style.facilitiesdiv}>
                <img src={Location} alt="" />
                <div className={Style.bigtext}>Acccessible Location</div>
                <div className={Style.smalltext}>
                  Easy to find on goggle map.
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className={Style.facilitiesdiv}>
                <img src={Solar} alt="" />
                <div className={Style.bigtext}>Zero Energy Building </div>
                <div className={Style.smalltext}>
                  Noiseless and ecofriendly energy supply
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className={Style.facilitiesdiv}>
                <img src={Parking} alt="" />
                <div className={Style.bigtext}>Parking Space</div>
                <div className={Style.smalltext}>
                  Proper parking arrangements for your vehicles.
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className={Style.facilitiesdiv}>
                <img src={Restaurant} alt="" />
                <div className={Style.bigtext}>Restaurant</div>
                <div className={Style.smalltext}>
                  A state of the art kitchen to meet all you need.
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className={Style.facilitiesdiv}>
                <img src={Offers} alt="" />
                <div className={Style.bigtext}>Unique and Special Offers</div>
                <div className={Style.smalltext}>
                  Unique and special offers for our special customers in their
                  special moments
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className={Style.facilitiesdiv}>
                <img src={SmartTV} alt="" />
                <div className={Style.bigtext}>Smart Television</div>
                <div className={Style.smalltext}>
                  Connect yourself to the rest of the world using our smart TVs.
                </div>
              </div>
            </Col>
            <Col md={6} lg={4}>
              <div className={Style.facilitiesdiv}>
                <img src={Serene} alt="" />
                <div className={Style.bigtext}>Serene Enviroment</div>
                <div className={Style.smalltext}>
                  A quiet environment for rest and peace of mind
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </a>
  );
};

export default Facilities;
