import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import Style from "../styles/rooms.module.css";
import ExecutiveRoom from "../assets/images/executive room.png";
import Room from "../assets/images/room.png";
import Kitchen from "../assets/images/kitchen.png";
import Passage from "../assets/images/passage.jpg";

const Gallery = () => {
  return (
    <a href=" " id="Gallery" className="anchor">
      <div className="root">
        <div className="sectiondiv">
          <div className={Style.roomtext}>Gallery</div>
          <Container>
            <Row>
              <Col md={12} lg={6}>
                <Row md={12} lg={6}>
                  <Col sm={12} md={6} lg={6} className={Style.col1}>
                    <img src={Room} alt="" className={Style.row1img} />
                  </Col>
                  <Col sm={12} md={6} lg={6} className={Style.col2}>
                    <img src={Kitchen} alt="" className={Style.row1imgg} />
                  </Col>
                </Row>
                <Row md={12} lg={6}>
                  <img src={Passage} alt="" className={Style.row2img} />
                </Row>
              </Col>
              <Col md={12} lg={6} className={Style.col3}>
                <img src={ExecutiveRoom} alt="" className={Style.col3img} />
              </Col>
            </Row>
          </Container>
          <div className={Style.viewtext}>View More &#8594;</div>
        </div>
      </div>
    </a>
  );
};

export default Gallery;
