import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Style from "../../styles/dashboard.module.css";
import { collection, doc, setDoc, getDocs, updateDoc } from "firebase/firestore";
import db from "../../Firebase";

const DashboardHero = () => {
  const [show, setShow] = React.useState(false);
  const [showCheckin, setShowCheckin] = React.useState(false);
  const [showCheckout, setShowCheckout] = React.useState(false);
  const [fullName, setFullName] = React.useState("");
  const [bookings, setBookings] = React.useState([]);
  const [checkin, setCheckin] = React.useState([]);
  const [checkinValue, setCheckinValue] = React.useState({});
  const [checkout, setCheckout] = React.useState([]);
  const [checkoutValue, setCheckoutValue] = React.useState({});
  const [bookingDate, setBookingDate] = React.useState("");
  const [roomNumber, setRoomNumber] = React.useState("");
  const [roomType, setRoomType] = React.useState("");
  const [guests, setGuests] = React.useState("");
  const [days, setDays] = React.useState("");
  const [status, setStatus] = React.useState(Number);
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [amount, setAmount] = React.useState(Number);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState(null);
  const [bookingsUpdated, setBookingsUpdated] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseCheckin = () => setShowCheckin(false);
  const handleShowCheckin = () => setShowCheckin(true);

  const handleCloseCheckout = () => setShowCheckout(false);
  const handleShowCheckout = () => setShowCheckout(true);

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
    setErrors(null);
  };
  const handleBookingDateChange = (e) => {
    setBookingDate(e.target.value);
    setErrors(null);
  };
  const handleRoomNumberChange = (e) => {
    setRoomNumber(e.target.value);
    setErrors(null);
  };
  const handleRoomTypeChange = (e) => {
    setRoomType(e.target.value);
    console.log(roomType);
    setErrors(null);
  };
  const handleGuestsChange = (e) => {
    setGuests(e.target.value);
    setErrors(null);
  };
  const handleDaysChange = (e) => {
    setDays(e.target.value);
    setErrors(null);
  };
  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
    setStatus(2);
    setErrors(null);
  };
  const handleCheckinValue = (e) => {
    setCheckinValue(JSON.parse(e.target.value));
    console.log(checkin);
    // console.log(checkin.map((check) => check.FullName));
    // console.log(checkinValue.BookingNumber);
    setErrors(null);
  };
  const handleCheckoutValue = (e) => {
    setCheckoutValue(JSON.parse(e.target.value));
    console.log(checkoutValue);
    console.log(checkoutValue.BookingNumber);
    setErrors(null);
  };

  React.useEffect(() => {
    if (bookingsUpdated === false) {
      const getBookings = async () => {
        const q = collection(db, "booking");
        const data = await getDocs(q);
        setBookings(data.docs.map((doc) => doc.data()))
        // console.log(data.docs.map((doc) => doc.data()));
        let checkinArray = []
        checkinArray.push(data.docs.map((doc) => doc.data()))
        console.log(checkinArray)
        setCheckin(checkinArray)
        console.log(checkinArray.filter((doc) => doc.Amount === 21500));
      };
      getBookings();
      setBookingsUpdated(true);
      
      // const booked = bookings.filter((booking) => booking.Status === 1);
      // setCheckin(booked);
      
      // const leaving = bookings.filter((booking) => booking.Status === 2);
      // setCheckout(leaving);
    }
  }, [bookings, bookingsUpdated]);

  const handleCheckin = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (checkinValue === "") {
      setLoading(false);
      setErrors("Please fill all needed fields.");
    } else {
      try {
        const checkRef = doc(db, "booking", `${checkinValue.BookingNumber}`);
        await updateDoc(checkRef, { Status: 2 }).then(() => {
          setTimeout(() => {
            setErrors(`Guest Checked In!!.`);
          }, 3000);
          setLoading(false);
          handleClose();
        });
      } catch (err) {
        console.error(err);
        setLoading(false);
        setErrors(err.message);
        handleCloseCheckin();
      }
    }
  };
  const handleCheckout = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (checkoutValue === "") {
      setLoading(false);
      setErrors("Please fill all needed fields.");
    } else {
      try {
        const checkRef = doc(db, "booking", `${checkoutValue.BookingNumber}`);
        await setDoc(checkRef, { Status: 3 }).then(() => {
          setTimeout(() => {
            setErrors(`Guest Checked Out!!.`);
          }, 3000);
          setLoading(false);
          handleClose();
        });
      } catch (err) {
        console.error(err);
        setLoading(false);
        setErrors(err.message);
        handleCloseCheckout();
      }
    }
  };

  const handleAddBooking = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      fullName === "" ||
      bookingDate === "" ||
      roomNumber === "" ||
      roomType === "" ||
      days === "" ||
      paymentMethod === "" ||
      guests === ""
    ) {
      setLoading(false);
      setErrors("Please fill all needed fields.");
    } else {
      if (roomType === "EXECUTIVE-PREMIUM") {
        setAmount(22500);
      } else if (roomType === "EXECUTIVE") {
        setAmount(19500);
      } else if (roomType === "PREMIUM") {
        setAmount(18500);
      } else if (roomType === "STANDARD") {
        setAmount(17500);
      }
      try {
        const bookingData = {
          FullName: fullName,
          BookingDate: bookingDate,
          RoomNumber: roomNumber,
          RoomType: roomType,
          Days: days,
          PaymentMethod: paymentMethod,
          Amount: amount,
          Status: status,
          Guests: guests,
          BookingNumber: `GV-${bookingDate}-${roomNumber}`,
        };
        await setDoc(
          doc(
            db,
            "bookings",
            `GV-${bookingData.BookingDate}-${bookingData.RoomNumber}`
          ),
          bookingData
        ).then(() => {
          setTimeout(() => {
            setErrors(`Booking Added!!.`);
          }, 3000);
          setLoading(false);
          handleClose();
        });
      } catch (err) {
        console.error(err);
        setLoading(false);
        setErrors(err.message);
        handleClose();
      }
    }
  };

  return (
    <div className="root">
      <div className={Style.smalltable}>
        <table className={Style.maintable}>
          <thead className={Style.tablehead}>
            <tr>
              <td className={Style.tabledata}>Room Type</td>
              <td className={Style.tabledata}>Available</td>
              <td className={Style.tabledata}>Occupied</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={Style.tabledata}>Premium Executive Room</td>
              <td className={Style.tabledata}>5</td>
              <td className={Style.tabledata}>0</td>
            </tr>
            <tr>
              <td className={Style.tabledata}>Executive Room</td>
              <td className={Style.tabledata}>1</td>
              <td className={Style.tabledata}>0</td>
            </tr>
            <tr>
              <td className={Style.tabledata}>Premium Room</td>
              <td className={Style.tabledata}>3</td>
              <td className={Style.tabledata}>0</td>
            </tr>
            <tr>
              <td className={Style.tabledata}>Standard Room</td>
              <td className={Style.tabledata}>9</td>
              <td className={Style.tabledata}>0</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className={Style.searchdiv}>
        <button className={Style.checkinbutton} onClick={handleShowCheckin}>
          Checkin
        </button>
        <button className={Style.checkoutbutton} onClick={handleShowCheckout}>
          Checkout
        </button>
        <button className={Style.bookingbutton} onClick={handleShow}>
          Add new Booking
        </button>
      </div>
      <div className={Style.bigtable}>
        <table className={Style.maintable}>
          <thead className={Style.tablehead}>
            <tr>
              <td className={Style.tabledata}>Full Name</td>
              <td className={Style.tabledata}>Booking Number</td>
              <td className={Style.tabledata}>Room Number</td>
              <td className={Style.tabledata}>Room Type</td>
              <td className={Style.tabledata}>Guest</td>
              <td className={Style.tabledata}>Days</td>
              <td className={Style.tabledata}>Payment Method</td>
              <td className={Style.tabledata}>Amount</td>
              <td className={Style.tabledata}>Status</td>
            </tr>
          </thead>
          <tbody>
            {bookings?.map((booking, index) => (
              <tr key={index}>
                <td className={Style.tabledata}>{booking.FullName}</td>
                <td className={Style.tabledata}>{booking.BookingNumber}</td>
                <td className={Style.tabledata}>{booking.RoomNumber}</td>
                <td className={Style.tabledata}>{booking.RoomType}</td>
                <td className={Style.tabledata}>{booking.Guests}</td>
                <td className={Style.tabledata}>{booking.Days}</td>
                <td className={Style.tabledata}>{booking.PaymentMethod}</td>
                <td className={Style.tabledata}>{booking.Amount}</td>
                <td className={Style.tabledata}>
                  {booking.Status === 1 ? (
                    <p
                      style={{
                        backgroundColor: "#fbb040",
                        color: "#fff",
                        width: "100px",
                        height: "20px",
                        textAlign: "center",
                      }}
                    >
                      Booked
                    </p>
                  ) : booking.Status === 2 ? (
                    <p
                      style={{
                        backgroundColor: "#56C568",
                        color: "#fff",
                        width: "100px",
                        height: "20px",
                        textAlign: "center",
                      }}
                    >
                      Active
                    </p>
                  ) : (
                    <p
                      style={{
                        backgroundColor: "#eb5757",
                        color: "#fff",
                        width: "100px",
                        height: "20px",
                        textAlign: "center",
                      }}
                    >
                      Out
                    </p>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Modal show={showCheckin} onHide={handleCloseCheckin}>
        <div className={Style.checkinmodal}>
          <div className={Style.bookingtext}>Checkin a Guest</div>
          <div className={Style.smallbookingtext}>
            Please Select Guest's Fullname & Booking Number
          </div>
          <Form>
            <Form.Select
              onClick={handleCheckinValue}
              className={Style.modalinput}
            >
              <option>Please Select</option>
              {checkin.map((check, index) => {
                return (
                  <option key={index} value={JSON.stringify(check)}>
                    {check.FullName} - {check.BookingNumber}
                  </option>
                );
              })}
            </Form.Select>
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>{errors}</p>
            )}
            <button
              type="submit"
              className={Style.modalbutton}
              onClick={handleCheckin}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </Form>
        </div>
      </Modal>
      <Modal show={showCheckout} onHide={handleCloseCheckout}>
        <div className={Style.checkinmodal}>
          <div className={Style.bookingtext}>Checkout a Guest</div>
          <div className={Style.smallbookingtext}>
            Please Select Guest's Fullname & Booking Number
          </div>
          <Form>
            <Form.Select
              onClick={handleCheckoutValue}
              className={Style.modalinput}
            >
              <option>Please Select</option>
              {checkout.map((check, index) => {
                return (
                  <option key={index} value={JSON.stringify(check)}>
                    {check.FullName} - {check.BookingNumber}
                  </option>
                );
              })}
            </Form.Select>
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>{errors}</p>
            )}
            <button
              type="submit"
              className={Style.modalbutton}
              onClick={handleCheckout}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </Form>
        </div>
      </Modal>
      <Modal show={show} onHide={handleClose}>
        <div className={Style.bookingmodal}>
          <div className={Style.bookingtext}>Add New Booking</div>
          <div className={Style.smallbookingtext}>
            Fill in details to add New Booking
          </div>
          <Form className={Style.bookingform}>
            <Form.Group>
              <Form.Control
                type="date"
                placeholder="Check-in Date"
                value={bookingDate}
                className={Style.modalinput}
                onChange={handleBookingDateChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="number"
                value={days}
                placeholder="Number of Nights"
                className={Style.modalinput}
                onChange={handleDaysChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                value={guests}
                placeholder="Guest"
                className={Style.modalinput}
                onChange={handleGuestsChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                value={fullName}
                placeholder="Full Name"
                className={Style.modalinput}
                onChange={handleFullNameChange}
              />
            </Form.Group>
            <Form.Select
              value={roomType}
              onChange={handleRoomTypeChange}
              className={Style.modalinput}
            >
              <option>Room Type</option>
              <option value="EXECUTIVE-PREMIUM">PREMIUM-EXECUTIVE</option>
              <option value="EXECUTIVE">EXECUTIVE</option>
              <option value="PREMIUM">PREMIUM</option>
              <option value="STANDARD">STANDARD</option>
            </Form.Select>
            <Form.Select
              value={roomNumber}
              onChange={handleRoomNumberChange}
              className={Style.modalinput}
            >
              <option>Room Number</option>
              <option value="PE821">PE821</option>
              <option value="PE822">PE822</option>
              <option value="PE823">PE823</option>
              <option value="PE824">PE824</option>
              <option value="PE825">PE825</option>
              <option value="E826">E826</option>
              <option value="P806">P802</option>
              <option value="P809">P803</option>
              <option value="P810">P804</option>
              {/* <option value="P811">P811</option>
              <option value="P812">P812</option>
              <option value="P813">P813</option>
              <option value="P814">P814</option>
              <option value="P815">P815</option>
              <option value="S802">S802</option>
              <option value="S803">S803</option>
              <option value="S804">S804</option>
              <option value="S807">S807</option>
              <option value="S808">S808</option> */}
              <option value="S827">S827</option>
            </Form.Select>
            <Form.Select
              value={paymentMethod}
              onChange={handlePaymentMethodChange}
              className={Style.modalinput}
            >
              <option>Payment Method </option>
              <option value="Cash">Cash</option>
              <option value="POS">POS</option>
              <option value="Transfer">Transfer</option>
            </Form.Select>
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>{errors}</p>
            )}
            <button
              type="submit"
              className={Style.modalbutton}
              onClick={handleAddBooking}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default DashboardHero;
