import React from "react";
import Header from "../admin/components/SignInHeader";
import LogoHero from "../admin/components/SignUpLogoHero";
import BookingHero from "../booking/components/BookingHero";

const Booking = () => {
  return (
    <div>
      <Header />
      <LogoHero />
      <BookingHero />
    </div>
  );
};

export default Booking;
