import { Carousel } from "react-bootstrap";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Receptionlogo from "../assets/images/receptionlogo.jpg";
import Sitting from "../assets/images/sittingarea.png";
import Outside from "../assets/images/outside.jpg";
import Style from "../styles/about.module.css";

const About = () => {
  return (
    <a href=" " id="About" className="anchor">
      <div className="root">
        <Container className="sectiondiv">
          <Row>
            <Col md={12} lg={6} className={Style.carouseldiv}>
              <Carousel>
                <Carousel.Item>
                  <img src={Outside} alt="GloryVille Front View" width={500} />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={Sitting}
                    alt="GloryVille Sitting Area"
                    width={500}
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    src={Receptionlogo}
                    alt="GloryVille Reception"
                    width={500}
                  />
                </Carousel.Item>
              </Carousel>
            </Col>
            <Col md={12} lg={6}>
              <div>
                <div className={Style.abouttext}>About</div>
                <div className={Style.smalltext}>
                  We are the heart and life of hospitality in Ile Ife! Located
                  in the heart of the city's serenity, we offer our exquisite
                  services to people from all walks of life. We pride ourselves
                  in satisfying the needs of every guest or visitor needing a
                  home away from home.
                </div>
                <div className={Style.smalltext}>
                  With a neat blend of African hospitality and our
                  state-of-the-art facility, we endeavour to provide the
                  amenities you need to feel at home perfect peace and serenity.
                </div>
                <div className={Style.smalltext}>
                  We've furnished our three tier rooms for your maximum comfort.
                  We run a world class kitchen that will satisfy all your
                  cravings and longing with speed and excellence. You can
                  breathe fresh air in an environment of quiet and solitude.
                </div>
                <div className={Style.smalltext}>
                  We are in the business of satisfying your needs and our staff
                  are passionate about helping you get the royal treatment you
                  merit.
                </div>
                <div className={Style.smalltext}>
                  We wish to see you soon. Welcome to Glory Ville, your home
                  away from home.
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </a>
  );
};

export default About;
