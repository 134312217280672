import React from "react";
import SignInHeader from "../admin/components/SignInHeader";
import SignInHero from "../admin/components/SignInHero";
import SignUpLogoHero from "../admin/components/SignUpLogoHero";

const SignIn = () => {
  return (
    <>
      <SignInHeader />
      <SignUpLogoHero />
      <SignInHero />
    </>
  );
};

export default SignIn;
