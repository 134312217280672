import React from "react";
import { Container, Nav, Navbar, Row, Col, Form, Modal } from "react-bootstrap";
import Logo from "../assets/icons/gloryville-logo.png";
import Style from "../styles/landing.module.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LogoHero = () => {
  const [show, setShow] = React.useState(false);
  const [nights, setNights] = React.useState("");
  const [guest, setGuest] = React.useState("");
  const [date, setDate] = React.useState("");
  const [errors, setErrors] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDateChange = (e) => {
    setDate(e.target.value);
    console.log(date);
    setErrors(null);
  };

  const handleGuestChange = (e) => {
    setGuest(e.target.value);
    console.log(guest);
    setErrors(null);
  };

  const handleNightChange = (e) => {
    setNights(e.target.value);
    console.log(nights);
    setErrors(null);
  };

  toast.configure();

  const handleAvailability = (e) => {
    e.preventDefault();
    setLoading(true);

    if (date === "" || guest === "" || nights === "") {
      setLoading(false);
      toast.error("Please fill all needed fields.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        transitionIn: "fadeIn",
        transitionOut: "fadeOut",
        closeOnToastrClick: true,
      });
    } else {
      localStorage.setItem("BookingDate", date);
      localStorage.setItem("Guest", guest);
      localStorage.setItem("Days", nights);
      navigate("/booking/room");
    }
  };

  const handlePhoneAvailability = (e) => {
    e.preventDefault();
    setLoading(true);

    if (date === "" || guest === "" || nights === "") {
      setLoading(false);
      setErrors("Please fill all needed fields.");
    } else {
      localStorage.setItem("BookingDate", date);
      localStorage.setItem("Guest", guest);
      localStorage.setItem("Days", nights);
      navigate("/booking/room");
    }
  };

  return (
    <div className="root">
      <Container>
        <Row>
          <Navbar>
            <Col lg={4}>
              <img
                src={Logo}
                className={Style.gvlogo}
                alt="Gloryville Hotel logo"
              />
            </Col>
            <Col lg={8} className={Style.navbarScroll}>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav
                  className="me-auto my-2 my-lg-0"
                  style={{ maxHeight: "100px" }}
                  navbarScroll
                >
                  <Nav.Link href="#" active="" className="text-light">
                    Home
                  </Nav.Link>
                  <Nav.Link href="#About" className="text-light">
                    About us
                  </Nav.Link>
                  <Nav.Link href="#Rooms" className="text-light">
                    Our rooms
                  </Nav.Link>
                  <Nav.Link href="#Facilities" className="text-light">
                    Facilities
                  </Nav.Link>
                  <Nav.Link href="#Gallery" className="text-light">
                    Gallery
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Col>
          </Navbar>
        </Row>
      </Container>

      <div className="container">
        <h1 className={Style.herotext}>GloryVille hotel</h1>
        <p className={Style.herosmall}>Home away from home</p>
      </div>

      <div className="container">
        <div className={Style.db_head}>
          <Form className={Style.db_form}>
            <Form.Control
              type="date"
              placeholder="Check-in Date"
              className={Style.dateinput}
              value={date}
              onChange={handleDateChange}
            />
            <Form.Select
              className={Style.db_input}
              value={nights}
              onChange={handleNightChange}
            >
              <option value=" ">Number of Nights</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={0}>More</option>
            </Form.Select>
            <Form.Select
              className={Style.db_input}
              value={guest}
              onChange={handleGuestChange}
            >
              <option value=" ">Please Select Guest</option>
              <option value="1 Adult">1 Adult</option>
              <option value="1 Adult, 1 Child">1 Adult, 1 Child</option>
              <option value="2 Adult">2 Adults</option>
              <option value="2 Adults, 1 Child">2 Adults, 1 Child</option>
              <option value="2 Adults, 2 Children">2 Adults, 2 Children</option>
            </Form.Select>
            <button className={Style.dbbtn} onClick={handleAvailability}>
              Check Availability
            </button>
          </Form>
        </div>
        <div className={Style.phonebtn}>
          <button className={Style.btn} onClick={handleShow}>
            Check Availability
          </button>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <div className={Style.bookingmodal}>
          <Form className={Style.bookingform}>
            <Form.Control
              type="date"
              placeholder="Check-in Date"
              className={Style.dbinput}
              value={date}
              onChange={handleDateChange}
            />
            <Form.Select
              className={Style.dbinput}
              value={nights}
              onChange={handleNightChange}
            >
              <option value=" ">Number of Nights</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={0}>More</option>
            </Form.Select>
            <Form.Select
              className={Style.dbinput}
              value={guest}
              onChange={handleGuestChange}
            >
              <option value=" ">Please Select Guest</option>
              <option value="1 Adult">1 Adult</option>
              <option value="1 Adult, 1 Child">1 Adult, 1 Child</option>
              <option value="2 Adults">2 Adults</option>
              <option value="2 Adults, 1 Child">2 Adults, 1 Child</option>
              <option value="2 Adults, 2 Children">2 Adults, 2 Children</option>
            </Form.Select>
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>{errors}</p>
            )}
            <button className={Style.btnn} onClick={handlePhoneAvailability}>
              {loading ? "Checking..." : "Check Availability"}
            </button>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default LogoHero;
