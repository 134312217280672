import React from "react";
import Header from "../admin/components/SignInHeader";
import DashboardLogoHero from "../admin/components/DashboardLogoHero";
import DashboardHero from "./components/DashboardHero";

const Dashboard = () => {
  return (
    <>
      <Header />
      <DashboardLogoHero />
      <DashboardHero />
    </>
  );
};

export default Dashboard;
