import React, { useState } from "react";
import Style from "../../styles/signuphero.module.css";
import { Form } from "react-bootstrap";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../Firebase";
import { useNavigate } from "react-router-dom";

const SignInHero = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrors(null);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrors(null);
  };

  const handleSignup = async () => {
    setLoading(true);
    if (email === "" || password === "") {
      setLoading(false);
      setErrors("Please fill all needed fields.");
    } else {
      try {
        await signInWithEmailAndPassword(auth, email, password).then((doc) => {
          setLoading(false);
          const token = Math.floor(Math.random() * 1000000000000000);
          localStorage.setItem("userId", doc.user.uid);
          console.log(doc.user.uid);
          localStorage.setItem("token", token);
          navigate("/admin");
        });
      } catch (err) {
        console.error(err);
        setLoading(false);
        setErrors(err.message);
      }
    }
  };

  return (
    <div className="root">
      <div className={Style.container}>
        <div>
          <div className={Style.signuptext}>Signin Details</div>
          <div className={Style.detailstext}>Enter Admin details to Signin</div>
        </div>
        <div>
          <Form>
            <Form.Group>
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                className={Style.inputcheck}
                onChange={handleEmailChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                className={Style.inputcheck}
                onChange={handlePasswordChange}
              />
            </Form.Group>
          </Form>
          {errors && (
            <p style={{ color: "red", textAlign: "center" }}>{errors}</p>
          )}
          <button className={Style.buttoncheck} onClick={handleSignup}>
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignInHero;
