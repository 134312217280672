import React from "react";
import Style from "../../styles/landing.module.css";
import Instagram from "../../assets/icons/instagram_black.png";
import Facebook from "../../assets/icons/facebook_black.png";
import Twitter from "../../assets/icons/twitter_black.png";

const SignInHeader = () => {
  return (
    <div className="root">
      <div className={Style.head}>
        <h5 className={Style.addresstexts}>
          A37,39 Engr. Kehinde Awoyele Street, Iloromu quarters, Ife
        </h5>
        <div className={Style.icondiv}>
          <div className={Style.heading}>
            <a
              href="https://www.instagram.com/gloryvillehotel_ife/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={Instagram}
                alt="Instagram icon"
                className={Style.logo}
              />
            </a>
          </div>
          <div className={Style.heading}>
            <a
              href="https://web.facebook.com/gloryvillehotel.ife"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Facebook} alt="Facebook icon" className={Style.logo} />
            </a>
          </div>
          <div className={Style.heading}>
            <a
              href="https://www.twitter.com/gloryvillehotel/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Twitter} alt="Twitter icon" className={Style.logo} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInHeader;
