import React from "react";
import { Card, Carousel, Col, Container, Row } from "react-bootstrap";
import Style from "../../styles/booking.module.css";
import PremiumExecutive1 from "../../assets/images/PremiumExecutive1.jpg";
import PremiumExecutive2 from "../../assets/images/PremiumExecutive2.jpg";
import PremiumExecutive3 from "../../assets/images/PremiumExecutive3.JPG";
import Executive1 from "../../assets/images/Executive1.png";
import Executive2 from "../../assets/images/Executive2.jpg";
import Executive3 from "../../assets/images/Executive3.jpg";
import Premium1 from "../../assets/images/Premium1.jpg";
import Premium2 from "../../assets/images/Premium2.png";
import Premium3 from "../../assets/images/Premium3.jpg";
import Standard1 from "../../assets/images/Standard1.JPG";
import Standard2 from "../../assets/images/Standard2.JPG";
import Standard3 from "../../assets/images/Standard3.JPG";
import { useNavigate } from "react-router-dom";
import People from "../../assets/icons/people.svg";

const ChooseRoom = () => {
  const navigate = useNavigate();

  const handlePremiumExecutive = (e) => {
    e.preventDefault();
    localStorage.setItem("RoomType", "PREMIUM-EXECUTIVE");
    localStorage.setItem("RoomNumber", "PE001");
    localStorage.setItem("Amount", "22,500");
    navigate("/booking/guestinfo");
  };

  const handleExecutive = (e) => {
    e.preventDefault();
    localStorage.setItem("RoomType", "EXECUTIVE");
    localStorage.setItem("RoomNumber", "E001");
    localStorage.setItem("Amount", "19,500");
    navigate("/booking/guestinfo");
  };

  const handlePremium = (e) => {
    e.preventDefault();
    localStorage.setItem("RoomType", "PREMIUM");
    localStorage.setItem("RoomNumber", "P001");
    localStorage.setItem("Amount", "18,500");
    navigate("/booking/guestinfo");
  };

  const handleStandard = (e) => {
    e.preventDefault();
    localStorage.setItem("RoomType", "STANDARD");
    localStorage.setItem("RoomNumber", "S001");
    localStorage.setItem("Amount", "17,500");
    navigate("/booking/guestinfo");
  };

  return (
    <div className="root">
      <div>
        <Container>
          <Row>
            <Col sm={12} md={12} lg={6}>
              <Card className={Style.cardcontainer}>
                <Carousel>
                  <Carousel.Item>
                    <img
                      src={PremiumExecutive1}
                      alt="GloryVille Premium Executive Room"
                      className={Style.images}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src={PremiumExecutive2}
                      alt="GloryVille Premium Executive Room"
                      className={Style.images}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src={PremiumExecutive3}
                      alt="GloryVille Premium Executive Room"
                      className={Style.images}
                    />
                  </Carousel.Item>
                </Carousel>
                <Card.Body>
                  <Card.Title className={Style.cardtitle}>
                    <div>PREMIUM EXECUTIVE ROOM</div>
                    <div className={Style.peoplediv}>
                      <img
                        src={People}
                        alt="People icon"
                        className={Style.icons}
                      />
                      <span className={Style.peopletext}>2 Guests</span>
                    </div>
                  </Card.Title>
                  <Card.Text className={Style.cardtext}>
                    <div className={Style.pricediv}>
                      <div className={Style.price}>&#8358;22,500</div>
                      <button
                        className={Style.selectbutton}
                        onClick={handlePremiumExecutive}
                      >
                        Select
                      </button>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <Card className={Style.cardcontainer}>
                <Carousel>
                  <Carousel.Item>
                    <img
                      src={Executive1}
                      alt="GloryVille Executive Room"
                      className={Style.images}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src={Executive2}
                      alt="GloryVille Executive Room"
                      className={Style.images}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src={Executive3}
                      alt="GloryVille Executive Room"
                      className={Style.images}
                    />
                  </Carousel.Item>
                </Carousel>
                <Card.Body>
                  <Card.Title className={Style.cardtitle}>
                    <div>EXECUTIVE ROOM</div>
                    <div className={Style.peoplediv}>
                      <img
                        src={People}
                        alt="People icon"
                        className={Style.icons}
                      />
                      <span className={Style.peopletext}>2 Guests</span>
                    </div>
                  </Card.Title>
                  <Card.Text className={Style.cardtext}>
                    <div className={Style.pricediv}>
                      <div className={Style.price}>&#8358;19,500</div>
                      <button
                        className={Style.selectbutton}
                        onClick={handleExecutive}
                      >
                        Select
                      </button>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <Card className={Style.cardcontainer}>
                <Carousel>
                  <Carousel.Item>
                    <img
                      src={Premium1}
                      alt="GloryVille Premium Room"
                      className={Style.images}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src={Premium2}
                      alt="GloryVille Premium Room"
                      className={Style.images}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src={Premium3}
                      alt="GloryVille Premium Room"
                      className={Style.images}
                    />
                  </Carousel.Item>
                </Carousel>
                <Card.Body>
                  <Card.Title className={Style.cardtitle}>
                    PREMIUM ROOM
                    <div className={Style.peoplediv}>
                      <img
                        src={People}
                        alt="People icon"
                        className={Style.icons}
                      />
                      <span className={Style.peopletext}>2 Guests</span>
                    </div>
                  </Card.Title>
                  <Card.Text className={Style.cardtext}>
                    <div className={Style.pricediv}>
                      <div className={Style.price}>&#8358;18,500</div>
                      <button
                        className={Style.selectbutton}
                        onClick={handlePremium}
                      >
                        Select
                      </button>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12} md={12} lg={6}>
              <Card className={Style.cardcontainer}>
                <Carousel>
                  <Carousel.Item>
                    <img
                      src={Standard1}
                      alt="GloryVille Standard Room"
                      className={Style.images}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src={Standard2}
                      alt="GloryVille Standard Room"
                      className={Style.images}
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src={Standard3}
                      alt="GloryVille Standard Room"
                      className={Style.images}
                    />
                  </Carousel.Item>
                </Carousel>
                <Card.Body>
                  <Card.Title className={Style.cardtitle}>
                    STANDARD ROOM
                    <div className={Style.peoplediv}>
                      <img
                        src={People}
                        alt="People icon"
                        className={Style.icons}
                      />
                      <span className={Style.peopletext}>2 Guests</span>
                    </div>
                  </Card.Title>
                  <Card.Text className={Style.cardtext}>
                    <div className={Style.pricediv}>
                      <div className={Style.price}>&#8358;17,500</div>
                      <button
                        className={Style.selectbutton}
                        onClick={handleStandard}
                      >
                        Select
                      </button>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ChooseRoom;
