import React from "react";
import Style from "../../styles/booking.module.css";
import PremiumExecutive from "../../assets/images/PremiumExecutive1.jpg";
import { Form } from "react-bootstrap";
import People from "../../assets/icons/people.svg";
import { Link, useNavigate } from "react-router-dom";

const GuestInfo = () => {
  const [email, setEmail] = React.useState("");
  const [fullName, setFullName] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState(null);

  const navigate = useNavigate();
  const RoomType = localStorage.getItem("RoomType");
  const Amount = localStorage.getItem("Amount");

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
    setErrors(null);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrors(null);
  };

  const handleGuestInfo = (e) => {
    e.preventDefault();
    setLoading(true);
    if (fullName === "" || email === "") {
      setLoading(false);
      setErrors("Please fill all needed fields.");
    } else {
      localStorage.setItem("FullName", fullName);
      localStorage.setItem("Email", email);
      navigate("/booking/payment");
    }
  };
  return (
    <div className="root">
      <div>
        <div className={Style.roomdiv}>
          <img
            src={PremiumExecutive}
            alt="GloryVille PremiumExecutive Room"
            className={Style.roomimage}
          />
          <div className={Style.roomdetailsdiv}>
            <div className={Style.roomtypediv}>
              <div className={Style.peopletext}>{RoomType} ROOM</div>
              <div className={Style.smallprice}>&#8358;{Amount}</div>
            </div>
            <div className={Style.peoplediv}>
              <img src={People} alt="People icon" className={Style.icons} />
              <span className={Style.peopletext}>2 Guests</span>
            </div>
            <div className={Style.changeroomdiv}>
              <div className={Style.peopletext}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt magna aliqua. Ut enim ad minim veniam,
                quis nostrud exercitation ullamco laboris nisi ut
              </div>
              <Link to="/booking/room" className={Style.linkdetails}>
                <button className={Style.buttondetails}>Change Room</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={Style.container}>
        <div>
          <div className={Style.signuptext}>Guest Information</div>
          <div className={Style.detailstext}>Please enter your Information</div>
        </div>
        <div>
          <Form>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Name"
                value={fullName}
                className={Style.inputcheck}
                onChange={handleFullNameChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                className={Style.inputcheck}
                onChange={handleEmailChange}
              />
            </Form.Group>
            {errors && <p style={{ color: "red" }}>{errors}</p>}
            <button
              type="submit"
              className={Style.buttoncheck}
              onClick={handleGuestInfo}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default GuestInfo;
