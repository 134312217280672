import React from "react";
import Logo from "../assets/icons/gloryville_black.png";
import Style from "../styles/footer.module.css";

const Footer = () => {
  return (
    <div className="root">
      <div>
        <iframe
          title="GloryVille Hotel Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3955.687688269881!2d4.578809114562608!3d7.499690094590417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103839b3e8db25df%3A0xb606fa96c671fba8!2sGlory%20Ville%20hotel%2C%20bar%2C%20lounge%20and%20restaurant!5e0!3m2!1sen!2sng!4v1649421262596!5m2!1sen!2sng"
          width="100%"
          height="400"
          style={{ borderRadius: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className={Style.logodiv}>
        <div>
          <img
            src={Logo}
            alt="Gloryville Hotel logo"
            className={Style.logoimage}
          />
          <div className={Style.contactdiv} style={{ marginTop: "25px" }}>
            A37,39 Engr. Kehinde Awoyele Street, Zone A, Iloromu quarters 3,
            Opposite OAU Teaching Hospital Phase 2 Gate, Off Oranfe Street,
            Ile-Ife, Osun State, Nigeria.
          </div>
          <div className={Style.contactdiv}>GloryVilleHotels@gmail.com</div>
          <div className={Style.contactdiv}>+2349017462971</div>
        </div>
        <hr />
        <div className={Style.copyrightdiv}>
          Copyright &copy; {new Date().getFullYear()} GloryVille Hotel
        </div>
      </div>
    </div>
  );
};

export default Footer;
