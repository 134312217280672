import React from "react";
import Header from "../admin/components/SignInHeader";
import LogoHero from "../admin/components/SignUpLogoHero";
import ChangeDetails from "./components/ChangeDetails";
import ChooseRoomHero from "./components/ChooseRoomHero";

const ChooseRoom = () => {
  return (
    <>
      <Header />
      <LogoHero />
      <ChangeDetails />
      <ChooseRoomHero />
    </>
  );
};

export default ChooseRoom;
