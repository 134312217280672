import React from "react";
import SignInHeader from "../admin/components/SignInHeader";
import SignUpHero from "../admin/components/SignUpHero";
import LogoHero from "../admin/components/SignUpLogoHero";

const SignUp = () => {
  return (
    <>
      <SignInHeader />
      <LogoHero />
      <SignUpHero />
    </>
  );
};

export default SignUp;
