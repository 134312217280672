import React from "react";
import Header from "../admin/components/SignInHeader";
import LogoHero from "../admin/components/SignUpLogoHero";
import ChangeDetails from "./components/ChangeDetails";
import GuestInfoHero from "./components/GuestInfoHero";

const GuestInfo = () => {
  return (
    <div>
      <Header />
      <LogoHero />
      <ChangeDetails />
      <GuestInfoHero />
    </div>
  );
};

export default GuestInfo;
