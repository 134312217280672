import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import Booking from "./pages/Booking";
import BookingUnavailable from "./booking/BookingUnavailable";
import Dashboard from "./admin/Dashboard";
import ChooseRoom from "./booking/ChooseRoom";
import GuestInfo from "./booking/GuestInfo";
import Payment from "./booking/Payment";
import { IsAuthenticated } from "./ProtectedRoute";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/booking" element={<Booking />} />
          <Route path="/booking/unavailable" element={<BookingUnavailable />} />
          <Route path="/booking/room" element={<ChooseRoom />} />
          <Route path="/booking/guestinfo" element={<GuestInfo />} />
          <Route path="/booking/payment" element={<Payment />} />
          <Route path="/" exact element={<IsAuthenticated />}>
            <Route path="/admin" element={<Dashboard />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

export default App;
