import React from "react";
import Style from "../../styles/booking.module.css";
import { Link } from "react-router-dom";
import Calendar from "../../assets/icons/calendar.svg";
import People from "../../assets/icons/people.svg";

const ChangeDetails = () => {
  const Date = localStorage.getItem("BookingDate");
  const Guest = localStorage.getItem("Guest");
  return (
    <div className="root">
      <div className={Style.buttondiv}>
        <div className={Style.detailsdiv}>
          <img src={Calendar} alt="Calendar icon" className={Style.icons} />
          <span className={Style.spantext}>{Date}</span>
          <img src={People} alt="People icon" className={Style.icons} />
          <span className={Style.spantext}>{Guest}</span>
        </div>
        <Link to="/" className={Style.linkdetails}>
          <button className={Style.buttondetails}>Change Details</button>
        </Link>
      </div>
    </div>
  );
};

export default ChangeDetails;
