import React, { useState } from "react";
import Style from "../../styles/signuphero.module.css";
import { Form } from "react-bootstrap";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../Firebase";
import { setDoc, doc } from "firebase/firestore";
import db from "../../Firebase";
import { useNavigate } from "react-router-dom";

const SignUpHero = () => {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const navigate = useNavigate();

  const handleFullNameChange = (e) => {
    setFullname(e.target.value);
    setErrors(null);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrors(null);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setErrors(null);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setErrors(null);
  };

  const handleSignup = async () => {
    setLoading(true);
    if (
      fullname === "" ||
      email === "" ||
      password === "" ||
      confirmPassword === ""
    ) {
      setLoading(false);
      setErrors("Please fill all needed fields.");
    } else {
      try {
        await createUserWithEmailAndPassword(auth, email, password)
          .then((credentials) => {
            const newUser = {
              name: fullname,
              email: email,
              password: password,
            };
            setDoc(doc(db, "users", email), newUser);
            setErrors(
              `User with id ${credentials.user.uid} has been successfully created.`
            );
          })
          .then((doc) => {
            setLoading(false);
            navigate("/signin");
          });
      } catch (err) {
        console.error(err);
        setLoading(false);
        if (
          err.message ===
          "Firebase: Password should be at least 6 characters (auth/weak-password)."
        ) {
          setErrors(
            "Password is weak. Please make sure its more than 8 characters."
          );
        }
      }
    }
  };

  return (
    <div className="root">
      <div className={Style.container}>
        <div>
          <div className={Style.signuptext}>Signup Details</div>
          <div className={Style.detailstext}>
            Please provide details to register
          </div>
        </div>
        <div>
          <Form>
            <Form.Group>
              <Form.Control
                type="text"
                placeholder="Fullname"
                value={fullname}
                className={Style.inputcheck}
                onChange={handleFullNameChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="email"
                placeholder="Email"
                value={email}
                className={Style.inputcheck}
                onChange={handleEmailChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                className={Style.inputcheck}
                onChange={handlePasswordChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                className={Style.inputcheck}
                onChange={handleConfirmPasswordChange}
              />
            </Form.Group>
          </Form>
          {errors && (
            <p style={{ color: "red", textAlign: "center" }}>{errors}</p>
          )}
          <button className={Style.buttoncheck} onClick={handleSignup}>
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUpHero;
