import React from "react";
import Style from "../../styles/booking.module.css";
import PremiumExecutive from "../../assets/images/PremiumExecutive1.jpg";
import { Form, Modal } from "react-bootstrap";
import People from "../../assets/icons/people.svg";
import { doc, setDoc } from "firebase/firestore";
import db from "../../Firebase";

const BookingHero = () => {
  const [show, setShow] = React.useState(false);
  const [fullName, setFullName] = React.useState("");
  const [bookingDate, setBookingDate] = React.useState("");
  const [roomNumber, setRoomNumber] = React.useState("");
  const [roomType, setRoomType] = React.useState("");
  const [guests, setGuests] = React.useState("");
  const [days, setDays] = React.useState("");
  const [status, setStatus] = React.useState(Number);
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [amount, setAmount] = React.useState(Number);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleFullNameChange = (e) => {
    setFullName(e.target.value);
    setErrors(null);
  };
  const handleBookingDateChange = (e) => {
    setBookingDate(e.target.value);
    setErrors(null);
  };
  //   const handleRoomNumberChange = (e) => {
  //     setRoomNumber(e.target.value);
  //     setErrors(null);
  //   };
  const handleRoomTypeChange = (e) => {
    setRoomType(e.target.value);
    console.log(roomType);
    setErrors(null);
  };
  const handleGuestsChange = (e) => {
    setGuests(e.target.value);
    setErrors(null);
  };
  const handleDaysChange = (e) => {
    setDays(e.target.value);
    setErrors(null);
  };
  const handlePaymentMethodChange = (e) => {
    setPaymentMethod(e.target.value);
    setStatus(2);
    setErrors(null);
  };

  const RoomType = localStorage.getItem("RoomType");
  const Amount = localStorage.getItem("Amount");

  const handleAddBooking = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (
      fullName === "" ||
      bookingDate === "" ||
      roomType === "" ||
      days === "" ||
      paymentMethod === "" ||
      guests === ""
    ) {
      setLoading(false);
      setErrors("Please fill all needed fields.");
    } else {
      if (roomType === "EXECUTIVE-PREMIUM") {
        setAmount(22500);
      } else if (roomType === "EXECUTIVE") {
        setAmount(19500);
      } else if (roomType === "PREMIUM") {
        setAmount(18500);
      } else if (roomType === "STANDARD") {
        setAmount(17500);
      }
      if (RoomType === "EXECUTIVE-PREMIUM") {
        setRoomNumber("PE001");
      } else if (RoomType === "EXECUTIVE") {
        setRoomNumber("E001");
      } else if (RoomType === "PREMIUM") {
        setRoomNumber("P001");
      } else if (RoomType === "STANDARD") {
        setRoomNumber("S001");
      }
      try {
        const bookingData = {
          FullName: fullName,
          BookingDate: bookingDate,
          RoomNumber: roomNumber,
          RoomType: roomType,
          Days: days,
          PaymentMethod: paymentMethod,
          Amount: amount,
          Status: status,
          Guests: guests,
          BookingNumber: `GV-${bookingDate}-${roomNumber}`,
        };
        // await setDoc(
        //   doc(
        //     db,
        //     "booking",
        //     `GV-${bookingData.BookingDate}-${bookingData.RoomNumber}`
        //   ),
        //   bookingData
        // )
        await setDoc(doc(db, "booking", "gv-2"), bookingData).then(() => {
          setTimeout(() => {
            setErrors(`Booking Added!!.`);
          }, 3000);
          setLoading(false);
          handleClose();
        });
      } catch (err) {
        console.error(err);
        setLoading(false);
        setErrors(err.message);
        handleClose();
      }
    }
  };

  return (
    <div className="root">
      <div className={Style.containerr}>
        <div className={Style.roomdiv}>
          <img
            src={PremiumExecutive}
            alt="GloryVille PremiumExecutive Room"
            className={Style.roomimage}
          />
          <div className={Style.roomdetailsdiv}>
            <div className={Style.roomtypediv}>
              <div className={Style.peopletext}>{RoomType} ROOM</div>
              <div className={Style.smallprice}>&#8358;{Amount}</div>
            </div>
            <div className={Style.peoplediv}>
              <img src={People} alt="People icon" className={Style.icons} />
              <span className={Style.peopletext}>2 Guests</span>
            </div>
            <div className={Style.changeroomdiv}>
              <div className={Style.peopletext}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt magna aliqua. Ut enim ad minim veniam,
                quis nostrud exercitation ullamco laboris nisi ut
              </div>
              <button className={Style.buttondetails} onClick={handleShow}>
                Book Room
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <div className={Style.bookingmodal}>
          <div className={Style.bookingtext}>Add New Booking</div>
          <div className={Style.smallbookingtext}>
            Fill in details to add New Booking
          </div>
          <Form className={Style.bookingform}>
            <Form.Group>
              <Form.Control
                type="date"
                placeholder="Check-in Date"
                value={bookingDate}
                className={Style.modalinput}
                onChange={handleBookingDateChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type="text"
                value={fullName}
                placeholder="Full Name"
                className={Style.modalinput}
                onChange={handleFullNameChange}
              />
            </Form.Group>
            <Form.Select
              value={days}
              className={Style.modalinput}
              onChange={handleDaysChange}
            >
              <option value=" ">Number of Nights</option>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
              <option value={6}>6</option>
              <option value={7}>7</option>
              <option value={8}>8</option>
              <option value={9}>9</option>
              <option value={10}>10</option>
              <option value={0}>More</option>
            </Form.Select>
            <Form.Select
              value={guests}
              className={Style.modalinput}
              onChange={handleGuestsChange}
            >
              <option value=" ">Please Select Guest</option>
              <option value="1 Adult">1 Adult</option>
              <option value="1 Adult, 1 Child">1 Adult, 1 Child</option>
              <option value="2 Adult">2 Adults</option>
              <option value="2 Adults, 1 Child">2 Adults, 1 Child</option>
              <option value="2 Adults, 2 Children">2 Adults, 2 Children</option>
            </Form.Select>
            <Form.Select
              value={roomType}
              onChange={handleRoomTypeChange}
              className={Style.modalinput}
            >
              <option>Room Type</option>
              <option value="EXECUTIVE-PREMIUM">PREMIUM-EXECUTIVE</option>
              <option value="EXECUTIVE">EXECUTIVE</option>
              <option value="PREMIUM">PREMIUM</option>
              <option value="STANDARD">STANDARD</option>
            </Form.Select>
            <Form.Select
              value={paymentMethod}
              onChange={handlePaymentMethodChange}
              className={Style.modalinput}
            >
              <option>Payment Method </option>
              <option value="Offline">Offline</option>
            </Form.Select>
            {errors && (
              <p style={{ color: "red", textAlign: "center" }}>{errors}</p>
            )}
            <button
              type="submit"
              className={Style.modalbutton}
              onClick={handleAddBooking}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default BookingHero;
