import React from "react";
import Style from "../styles/rooms.module.css";
import { Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const Rooms = () => {
  const navigate = useNavigate();

  const handleExecutive = (e) => {
    e.preventDefault();
    localStorage.setItem("RoomType", "EXECUTIVE");
    localStorage.setItem("Amount", "19,500");
    navigate("/booking");
  };

  const handlePremium = (e) => {
    e.preventDefault();
    localStorage.setItem("RoomType", "PREMIUM");
    localStorage.setItem("Amount", "18,500");
    navigate("/booking");
  };

  const handleStandard = (e) => {
    e.preventDefault();
    localStorage.setItem("RoomType", "STANDARD");
    localStorage.setItem("Amount", "17,500");
    navigate("/booking");
  };
  return (
    <a href=" " id="Rooms" className="anchor">
      <div className="sectiondiv">
        <div className={Style.roomtext}>Rooms</div>
        <div className={Style.roombg}>
          <div className={Style.roomcard}>
            <Card className={Style.roomcard1}>
              <Card.Body>
                <Card.Title className={Style.cardtitle}>
                  Executive Room
                </Card.Title>
                <Card.Text className={Style.cardtext}>
                  <Link
                    to="/booking/room"
                    className={Style.viewlink}
                    onClick={handleExecutive}
                  >
                    View Details &#8594;
                  </Link>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className={Style.roomcard2}>
              <Card.Body>
                <Card.Title className={Style.cardtitle}>
                  Premium Room
                </Card.Title>
                <Card.Text className={Style.cardtext}>
                  <Link
                    to="/booking/room"
                    className={Style.viewlink}
                    onClick={handlePremium}
                  >
                    View Details &#8594;
                  </Link>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className={Style.roomcard3}>
              <Card.Body>
                <Card.Title className={Style.cardtitle}>
                  Standard Room
                </Card.Title>
                <Card.Text className={Style.cardtext}>
                  <Link
                    to="/booking/room"
                    className={Style.viewlink}
                    onClick={handleStandard}
                  >
                    View Details &#8594;
                  </Link>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
        <div className="root">
          <div className={Style.roomcardphone}>
            <Card className={Style.roomcard1}>
              <Card.Body>
                <Card.Title className={Style.cardtitle}>
                  Executive Room
                </Card.Title>
                <Card.Text className={Style.cardtext}>
                  <Link to="/booking/room" className={Style.viewlink}>
                    View Details &#8594;
                  </Link>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className={Style.roomcard2}>
              <Card.Body>
                <Card.Title className={Style.cardtitle}>
                  Premium Room
                </Card.Title>
                <Card.Text className={Style.cardtext}>
                  <Link to="/booking/room" className={Style.viewlink}>
                    View Details &#8594;
                  </Link>
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className={Style.roomcard3}>
              <Card.Body>
                <Card.Title className={Style.cardtitle}>
                  Standard Room
                </Card.Title>
                <Card.Text className={Style.cardtext}>
                  <Link to="/booking/room" className={Style.viewlink}>
                    View Details &#8594;
                  </Link>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>
    </a>
  );
};

export default Rooms;
