import React from "react";
import Style from "../../styles/landing.module.css";
import { Button, Navbar } from "react-bootstrap";
import Logoblack from "../../assets/icons/gloryville_black.png";
import { useNavigate } from "react-router-dom";

const DashboardLogoHero = () => {
  const navigate = useNavigate();
  const signOut = () => {
    localStorage.clear("token");
    navigate("/signin");
  };
  return (
    <div className="root">
      <div className={Style.logoutdiv}>
        <div>
          <Navbar.Brand>
            <img
              src={Logoblack}
              className={Style.gvlogo}
              alt="GloryVille Hotel logo"
            />
          </Navbar.Brand>
        </div>
        <div>
          <Button className={Style.buttoncheck} onClick={signOut}>
            Logout
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DashboardLogoHero;
