import React from "react";
import Style from "../../styles/booking.module.css";
import PremiumExecutive from "../../assets/images/PremiumExecutive1.jpg";
import { Form } from "react-bootstrap";
import People from "../../assets/icons/people.svg";
import { Link } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import db from "../../Firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Payment = () => {
  const [payment, setPayment] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState(null);

  const handlePaymentRadio = (e) => {
    setPayment(e.target.value);
  };
  const RoomType = localStorage.getItem("RoomType");
  const RoomNumber = localStorage.getItem("RoomNumber");
  const FullName = localStorage.getItem("FullName");
  const Email = localStorage.getItem("Email");
  const Guest = localStorage.getItem("Guest");
  const Days = localStorage.getItem("Days");
  const BookingDate = localStorage.getItem("BookingDate");
  const Amount = localStorage.getItem("Amount");

  toast.configure();

  const handleBooking = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (payment === "") {
      setLoading(false);
      setErrors("Please fill all needed fields.");
    } else {
      try {
        const bookingData = {
          FullName: FullName,
          BookingDate: BookingDate,
          RoomType: RoomType,
          RoomNumber: RoomNumber,
          Days: Days,
          PaymentMethod: payment,
          Amount: Amount,
          Status: 1,
          Guests: Guest,
          BookingNumber: `GV-${BookingDate}-${RoomNumber}`,
        };
        await setDoc(
          doc(db, "bookings", `GV-${BookingDate}-${RoomNumber}`),
          bookingData
        ).then(() => {
          setTimeout(() => {
            setErrors(`Booking Added!!.`);
          }, 3000);
          setLoading(false);
          toast.success(
            `Booking Added!!. Your Booking Number is ${bookingData.BookingNumber}. Please copy it and present it at the frontdesk of the hotel. `,
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: false,
              transitionIn: "fadeIn",
              transitionOut: "fadeOut",
              closeOnToastrClick: true,
            }
          );
        });
      } catch (err) {
        console.error(err);
        setLoading(false);
        setErrors(err.message);
      }
    }
  };
  return (
    <div className="root">
      <div>
        <div className={Style.roomdiv}>
          <img
            src={PremiumExecutive}
            alt="GloryVille PremiumExecutive Room"
            className={Style.roomimage}
          />
          <div className={Style.roomdetailsdiv}>
            <div className={Style.roomtypediv}>
              <div className={Style.peopletext}>{RoomType} ROOM</div>
              <div className={Style.smallprice}>&#8358;{Amount}</div>
            </div>
            <div className={Style.peoplediv}>
              <img src={People} alt="People icon" className={Style.icons} />
              <span className={Style.peopletext}>2 Guests</span>
            </div>
            <div className={Style.changeroomdiv}>
              <div className={Style.peopletext}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt magna aliqua. Ut enim ad minim veniam,
                quis nostrud exercitation ullamco laboris nisi ut
              </div>
              <div className={Style.flexdiv}>
                <Link to="/booking/room" className={Style.linkdetails}>
                  <button className={Style.buttondetails}>Change Room</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Style.guestdiv}>
        <div className={Style.guestinfodiv}>
          <div className={Style.infodiv}>
            <div className={Style.labeltext}>Name</div>
            <div className={Style.infotext}>{FullName}</div>
            <div className={Style.labelltext}>Email</div>
            <div className={Style.infotext}>{Email}</div>
          </div>
          <div className={Style.flexdiv}>
            <div className={Style.buttonsdiv}>
              <Link to="/booking/guestinfo" className={Style.linkdetails}>
                <button className={Style.buttondetails}>Edit Details</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={Style.container}>
        <div>
          <div className={Style.signuptext}>Payment Method</div>
          <div className={Style.detailstext}>
            Please choose your preferred payment method
          </div>
        </div>
        <div>
          <Form>
            <Form.Group>
              <Form.Check
                type="radio"
                label="Online"
                value="Online"
                name="payment"
                onChange={handlePaymentRadio}
                disabled={true}
              />
            </Form.Group>
            <Form.Group>
              <Form.Check
                type="radio"
                label="Offline"
                value="Offline"
                name="payment"
                onChange={handlePaymentRadio}
              />
            </Form.Group>
            {errors && <p style={{ color: "red" }}>{errors}</p>}
          </Form>
          <button className={Style.buttonradio} onClick={handleBooking}>
            {loading ? "Submitting..." : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Payment;
