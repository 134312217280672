import React from "react";
import Style from "../../styles/landing.module.css";
import { Navbar } from "react-bootstrap";
import Logoblack from "../../assets/icons/gloryville_black.png";

const SignUpLogoHero = () => {
  return (
    <div className="root">
      <div className={Style.Hero}>
        <Navbar.Brand>
          <img
            src={Logoblack}
            className={Style.gvlogo}
            alt="GloryVille Hotel logo"
          />
        </Navbar.Brand>
      </div>
    </div>
  );
};

export default SignUpLogoHero;
