import React from "react";
import LandingHero from "../components/LandingHero";
import About from "../components/About";
import Rooms from "../components/Rooms";
import Gallery from "../components/Gallery";
import Facilities from "../components/Facilities";
import Footer from "../components/Footer";

const Landing = () => {
  return (
    <div>
      <LandingHero />
      <About />
      <Rooms />
      <Facilities />
      <Gallery />
      <Footer />
    </div>
  );
};

export default Landing;
