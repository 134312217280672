import React from "react";
import Style from "../../styles/booking.module.css";

const BookingUnavailable = () => {
  return (
    <div className="root">
      <div>
        <div className={Style.errordiv}>
          No room available for the slected February 16 - February 18 try
          another date or contact the hotel.
        </div>
        <div className={Style.requestdiv}>
          You can leave a request for the schedule, you will be notified once
          the room is available
        </div>
        <button className={Style.requestbutton}>Make Request</button>
        <div className={Style.arrivaldiv}>Nearest Arrival Date</div>
        <div>
          <button className={Style.datebutton}>
            February 16 - February 18
          </button>
          <button className={Style.daysbutton}>Select Other Days</button>
        </div>
      </div>
    </div>
  );
};

export default BookingUnavailable;
