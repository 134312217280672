import React from "react";
import Header from "../admin/components/SignInHeader";
import LogoHero from "../admin/components/SignUpLogoHero";
import ChangeDetails from "./components/ChangeDetails";
import PaymentHero from "./components/PaymentHero";

const Payment = () => {
  return (
    <div>
      <Header />
      <LogoHero />
      <ChangeDetails />
      <PaymentHero />
    </div>
  );
};

export default Payment;
