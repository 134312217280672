import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyDZACy-GykAM1NW_PT6zVZnID0o6uR-XOg",
  authDomain: "gloryville-hotel.firebaseapp.com",
  projectId: "gloryville-hotel",
  storageBucket: "gloryville-hotel.appspot.com",
  messagingSenderId: "299362975882",
  appId: "1:299362975882:web:3341f2ddfff83e2686ed73",
  measurementId: "G-6DZP9VBBS6"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage(app);

// const db = firebase.firestore();

export default getFirestore();
// export default db;
