import React from "react";
import Style from "../styles/landing.module.css";
import Header from "../components/Header";
import LogoHero from "../components/LogoHero";

const Landing = () => {
  return (
    <div className={Style.herobg}>
      <Header />
      <LogoHero />
    </div>
  );
};

export default Landing;
