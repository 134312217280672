import React from "react";
import Header from "../admin/components/SignInHeader";
import LogoHero from "../admin/components/SignUpLogoHero";
import ChangeDetails from "./components/ChangeDetails";
import BookingUnavailableHero from "./components/BookingUnavailableHero";

const BookingUnavailable = () => {
  return (
    <div>
      <Header />
      <LogoHero />
      <ChangeDetails />
      <BookingUnavailableHero />
    </div>
  );
};

export default BookingUnavailable;
